import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import Hero from "./Hero";
import { scrollToTop } from "../../utils/helper";

const Home = () => {
  Aos.init({
    duration: 1800,
    offset: 0,
  });
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className="hidden-overflow">
      <Hero />
    </div>
  );
};

export default Home;
