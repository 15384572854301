import React, { useState } from "react";
import { navigationData } from "../../screens/Home/data";
import { NavLink } from "react-router-dom";
import { imgUrl } from "../../utils/helper";
import { ServicesList, serviceList } from "../../utils/Dummy";

function Footer() {
  const [selected, setSelected] = useState(null);
  const [selectedIndx, setSelectedIndx] = useState(null);

  const onSelect = (item, indx) => {
    if (item === selected) {
      setSelected(null);
      setSelectedIndx(null);
    } else {
      setSelected(item);
      setSelectedIndx(indx);
    }
  };
  return (
    <footer>
      {/* <div className="bg-[url(./assets/img/bg.png)] text-gray-200 p-5"> */}
      <div className="bg-black text-gray-200 p-5">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
            <div className="md:mb-5 text-center">
              <img
                src={imgUrl + "logo.png"}
                alt=""
                className="md:h-[40%] lg:h-[35%] mt-3 ml-1"
              />
              <div className="grid grid-cols-4 gap-1 py-4 px-6">
                <button
                  type="button"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  class="mb-2 inline-block rounded-full p-3 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg w-[40px]"
                  style={{ background: "#1877f2" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
                  </svg>
                </button>
                <button
                  type="button"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  class="mb-2 inline-block rounded-full p-3 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg w-[40px]"
                  style={{ background: "#c13584" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                  </svg>
                </button>
                <button
                  type="button"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  class="mb-2 inline-block rounded-full p-3 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg w-[40px]"
                  style={{ background: "#1769ff" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 7h-7v-2h7v2zm1.726 10c-.442 1.297-2.029 3-5.101 3-3.074 0-5.564-1.729-5.564-5.675 0-3.91 2.325-5.92 5.466-5.92 3.082 0 4.964 1.782 5.375 4.426.078.506.109 1.188.095 2.14h-8.027c.13 3.211 3.483 3.312 4.588 2.029h3.168zm-7.686-4h4.965c-.105-1.547-1.136-2.219-2.477-2.219-1.466 0-2.277.768-2.488 2.219zm-9.574 6.988h-6.466v-14.967h6.953c5.476.081 5.58 5.444 2.72 6.906 3.461 1.26 3.577 8.061-3.207 8.061zm-3.466-8.988h3.584c2.508 0 2.906-3-.312-3h-3.272v3zm3.391 3h-3.391v3.016h3.341c3.055 0 2.868-3.016.05-3.016z" />
                  </svg>
                </button>
                <button
                  type="button"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  class="mb-2 inline-block rounded-full p-3 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg w-[40px]"
                  style={{ background: "#ea4c89" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 0c-6.628 0-12 5.373-12 12s5.372 12 12 12 12-5.373 12-12-5.372-12-12-12zm9.885 11.441c-2.575-.422-4.943-.445-7.103-.073-.244-.563-.497-1.125-.767-1.68 2.31-1 4.165-2.358 5.548-4.082 1.35 1.594 2.197 3.619 2.322 5.835zm-3.842-7.282c-1.205 1.554-2.868 2.783-4.986 3.68-1.016-1.861-2.178-3.676-3.488-5.438.779-.197 1.591-.314 2.431-.314 2.275 0 4.368.779 6.043 2.072zm-10.516-.993c1.331 1.742 2.511 3.538 3.537 5.381-2.43.715-5.331 1.082-8.684 1.105.692-2.835 2.601-5.193 5.147-6.486zm-5.44 8.834l.013-.256c3.849-.005 7.169-.448 9.95-1.322.233.475.456.952.67 1.432-3.38 1.057-6.165 3.222-8.337 6.48-1.432-1.719-2.296-3.927-2.296-6.334zm3.829 7.81c1.969-3.088 4.482-5.098 7.598-6.027.928 2.42 1.609 4.91 2.043 7.46-3.349 1.291-6.953.666-9.641-1.433zm11.586.43c-.438-2.353-1.08-4.653-1.92-6.897 1.876-.265 3.94-.196 6.199.196-.437 2.786-2.028 5.192-4.279 6.701z" />
                  </svg>
                </button>
              </div>
            </div>
            <div className="md:mb-5">
              <h4 className="text-2xl pb-4">Company</h4>
              <p className="text-gray-300">
                Noida, India <br />
                <strong>Phone:</strong> +91 9294560772 <br />
                <strong>Email:</strong> truwainfotech@gmail.com <br />
              </p>
            </div>
            <div className="md:mb-5 md:mx-5">
              <h4 className="text-2xl pb-4">Useful Links</h4>

              <ul className="text-gray-300 pl-2">
                <li className="pb-2">
                  <i className="fa fa-chevron-right"></i>
                  <NavLink to={`/`} className="hover:text-lime-400">
                    {" "}
                    Home
                  </NavLink>
                </li>

                {navigationData.map((item, index) => (
                  <li className="pb-2">
                    <i className="fa fa-chevron-right"></i>
                    <NavLink
                      to={`/${item.route}`}
                      className="hover:text-lime-400"
                    >
                      {" "}
                      {item.name}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
            <div className="md:mb-5 md:mx-5">
              <h2 className="text-2xl pb-4">Our Services</h2>
              <ul className="text-gray-300 pl-2">
                {serviceList.map((item, index) => (
                  <li
                    key={index}
                    className="pb-2"
                    onClick={() => {
                      onSelect(item.name, index);
                    }}
                  >
                    <i
                      className={
                        item.name === selected
                          ? "fa fa-chevron-down"
                          : "fa fa-chevron-right"
                      }
                    ></i>{" "}
                    {item.title}
                    {selected &&
                      selectedIndx === index &&
                      ServicesList[selected].map((ite) => (
                        <ul className="text-gray-300 pl-8" key={ite.name}>
                          <li className="pb-1 hover:text-lime-500">
                            <NavLink
                              to={`/services`}
                              className="hover:text-lime-400"
                            >
                              {ite.name}
                            </NavLink>
                          </li>
                        </ul>
                      ))}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div>
            <div className="mb-5 md:mx-5 text-center text-lg pb-5 lg:mt-[-80px] mt-12 ">
              <h2>© Copyright 2023 Truwa Infotech</h2>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
