import React, { useEffect, useState } from "react";
import WorkHeader from "./Header";
import WorkSample from "./Sample";
import { scrollToTop } from "../../utils/helper";

function Work() {
  const [selected, setSelected] = useState("All");

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <main class="container mx-auto h-full hidden-overflow">
        <WorkHeader selected={selected} setSelected={setSelected} />
        <WorkSample selected={selected} />
      </main>
    </>
  );
}

export default Work;
