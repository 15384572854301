export const ContactData = {
  companyData: [
    {
      title: "Email",
      subtitle: "Reach out us on",
      value: "truwainfotech@gmail.com",
      slug: "email",
    },
    {
      title: "Office Location",
      subtitle: "Come say hello at our office HQ.",
      value: "Noida, India",
      slug: "office",
    },
    {
      title: "Phone",
      subtitle: "Mon-Sat from 10am to 7pm.",
      value: "+91 9294560772",
      slug: "phone",
    },
  ],
};
