import React, { useState } from "react";

const AboutCoursel = ({ data }) => {
  const [indexs, setIndexs] = useState(0);

  return (
    <div
      className="flex  w-full my-[50px] text-center container px-6 py-12 h-[300px]"
      data-aos="fade-left"
      data-aos-delay="1000"
    >
      <div className="text-center w-full mx-auto absolute bg-white py-14">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="78"
          height="78"
          fill="currentColor"
          className="bi bi-quote mx-auto"
          viewBox="0 0 16 16"
          id="IconChangeColor"
        >
          {" "}
          <path
            d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z"
            id="mainIconPathAttribute"
          ></path>{" "}
        </svg>
        <strong
          className="font-bold text-3xl md:text-6xl lg:text-4xl text-cyan-950"
          data-aos="fade-down"
          data-aos-delay="800"
        >
          {data[indexs].title}
        </strong>
        <p
          className="font-serif text-xl lg:text-3xl lg:leading-[2.6rem] mt-2 text-center w-[75%] mx-auto"
          data-aos="fade-down"
          data-aos-delay="800"
        >
          " {data[indexs].subtitle} "
        </p>
      </div>

      {indexs !== 0 && (
        <button
          className="btn btn-primary mb-8 xl:mb-0 py-4 rounded-lg text-6xl text-slate-950 px-5 absolute left-0absolute md:left-0 -left-14 z-10 top-[170px]"
          data-aos="fade-down"
          data-aos-delay="600"
          onClick={() => {
            setIndexs(indexs - 1);
          }}
          disabled={indexs === 0}
        >
          {"<"}
        </button>
      )}

      {indexs !== 2 && (
        <button
          className="btn btn-primary mb-8 xl:mb-0 py-4 rounded-lg text-6xl text-slate-950 px-5 absolute left-0absolute md:right-0 -right-14 z-10 top-[170px]"
          data-aos="fade-down"
          data-aos-delay="600"
          onClick={() => {
            setIndexs(indexs + 1);
          }}
          disabled={indexs === 2}
        >
          {">"}
        </button>
      )}
    </div>
  );
};

export default AboutCoursel;
