import React from "react";
import { aboutData } from "./data";
import Lottie from "lottie-react";
import contact from "../../assets/animation/contact.json";
import about from "../../assets/animation/about.json";
import AboutCoursel from "./AboutCoursel";

const AboutHeader = () => {
  const {
    title,
    subtitle,
    whosubtitle,
    whotitle,
    whatsubtitle,
    whattitle,
    aboutUS,
  } = aboutData;

  return (
    <section className="flex flex-col p-6 m-5">
      <div
        className="mx-auto text-center py-[50px]"
        data-aos="fade-down"
        data-aos-delay="400"
      >
        <p className="font-bold text-lg px-0 text-cyan-600">{title}</p>
        <p className="font-bold lg:w-[75%] lg:leading-[3.5rem] text-cyan-950 font-serif  text-xl lg:text-4xl my-5 px-0 mx-auto">
          {subtitle}
        </p>
      </div>
      <div
        class="flex flex-col lg:flex-row items-center my-[50px]"
        data-aos="fade-right"
        data-aos-delay="600"
      >
        <div className="lg:w-[600px] content-center items-center">
          <Lottie animationData={about} loop={true} />
        </div>
        <div class="lg:max-w-[60%] lg:px-5 mt-[50px]">
          <p className="font-bold font-serif  text-cyan-950 text-xl lg:text-4xl pb-8">
            {whotitle}
          </p>
          <p className="font-serif text-slate-600 leading-[2rem] text-xl ">
            {whosubtitle}
          </p>
        </div>
      </div>
      <div
        class="flex flex-col lg:flex-row items-center my-[50px]"
        data-aos="fade-right"
        data-aos-delay="800"
      >
        <div class="lg:max-w-[60%]">
          <p className="font-bold font-serif text-cyan-950 text-xl lg:text-4xl pb-6">
            {whattitle}
          </p>
          <p className="font-serif leading-[1.8rem] text-slate-600 text-xl">
            {" "}
            {whatsubtitle}
          </p>
        </div>
        <div className="text-center justify-center">
          <div className="h-[30vh] content-center items-center">
            <Lottie animationData={contact} loop={true} />
          </div>
        </div>
      </div>
      <AboutCoursel data={aboutUS} />
    </section>
  );
};

export default AboutHeader;
