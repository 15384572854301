import React, { useState, useEffect } from "react";
import ContactHeader from "./ContactHeader";
import ContactForm from "./ContactForm";
import ContactFooter from "./ContactFooter";
import { scrollToTop } from "../../utils/helper";

const Contact = () => {
  const [selecttype, setSelectType] = useState(null);
  const [subtype, setSubtype] = useState(null);
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [msg, setMsg] = useState("");

  useEffect(() => {
    scrollToTop();
  }, []);

  const EmailSubmit = ({ children }) => {
    const body = {
      email: email,
      name: name,
      company: company,
      phone: phone,
      msg: msg,
      selecttype: selecttype,
      subtype: subtype,
    };
    let subject = "Busiiness Enquiries";
    let params = body ? "?" : "";
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;

    return (
      <a
        href={`mailto:${"truwainfotech.com"}${params}`}
        className="bg-cyan-700 p-4 rounded-xl text-white"
      >
        {children}
      </a>
    );
  };

  return (
    <>
      <main className="container mx-auto h-full hidden-overflow scroll-smooth">
        <ContactHeader />
        <ContactForm
          selecttype={selecttype}
          subtype={subtype}
          setSelectType={setSelectType}
          setSubtype={setSubtype}
          name={name}
          setName={setName}
          company={company}
          setCompany={setCompany}
          email={email}
          setEmail={setEmail}
          phone={phone}
          setPhone={setPhone}
          msg={msg}
          setMsg={setMsg}
          onSubmit={() => <EmailSubmit>Send Data</EmailSubmit>}
        />
        <ContactFooter />
      </main>
    </>
  );
};

export default Contact;
