import React, { useEffect, useState } from "react";
import ServiceHeader from "./ServiceHeader";
import ServicesList from "./ServicesList";
import { scrollToTop } from "../../utils/helper";

function Services() {
  const [selected, setSelected] = useState("technology");
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <main className="container mx-auto h-full hidden-overflow">
      <ServiceHeader selected={selected} setSelected={setSelected} />
      <ServicesList selected={selected} />
    </main>
  );
}

export default Services;
