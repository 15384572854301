import React, { useEffect } from "react";
import AboutHeader from "./AboutHeader";
import { scrollToTop } from "../../utils/helper";

function About() {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <>
      <main class="container mx-auto h-full hidden-overflow">
        <AboutHeader />
      </main>
    </>
  );
}

export default About;
